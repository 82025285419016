/* You can add global styles to this file, and also import other style files */
@import '../../node_modules/primeflex/primeflex.css';
@import '../../node_modules/primeng/resources/themes/luna-green/theme.css';
@import '../../node_modules/primeng/resources/primeng.css';
@import '../../node_modules/primeicons/primeicons.css';

:root {
    --fonts: 'neue-haas-grotesk-text', 'Helvetica Neue', Arial, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Noto Sans', sans-serif;
    --default-font-color: #dddddd;
    --opex-green: #7cae15;
    --page-background-color: #171717;
    --panel-background-color: #1f1f1f;
    --light-gray: #555;
}

body {
    background-color: var(--page-background-color);
    color: var(--default-font-color);
    font-family: var(--fonts);
    min-height: 100%;
    margin: 0;
    padding-bottom: 55px;
    font-size: 16px;
}

canvas {
    image-rendering: pixelated;
}

section {
    padding-right: 8px;
    transition: margin-left 0.5s;
}

footer {
    position: fixed;
    height: 50px;
    bottom: 0px;
    width: 100%;
    border-top: 3px solid;
    border-top-color: var(--opex-green);
    background-color: var(--page-background-color);
    transition: padding-left 0.5s;
}

header {
    border-bottom: 3px solid;
    border-bottom-color: var(--opex-green);
    transition: margin-left 0.5s;
}

a {
    color: var(--default-font-color);
}

h3 {
    margin: 0;
    padding: 0;
}

sub {
    font-size: 0.75em;
}

.page-header-panel {
    width: 100%;
    background-color: var(--panel-background-color);
    padding: 5px;
}

.page-panel {
    background-color: var(--panel-background-color);
    padding: 5px;
}

.ai-color {
    color: var(--opex-green) !important;
}

.separator-bar {
    border: 3px solid var(--opex-green) !important;
    background-color: var(--opex-green);
    height: 100%;
    min-height: 38px;
}

.selectable-card {
    cursor: pointer;
}

.tenant-card {
    min-width: 200px;
}

.tenant-card .p-card {
    padding: 2px;
}

/* .asset-card {
    min-width: 450px;
} */

.button-default-outline {
    color: var(--opex-green) !important;
}

.p-sidebar {
    background: var(--panel-background-color) !important;
}

.p-sidebar h3 {
    color: var(--default-font-color);
}

.p-panelmenu .p-panelmenu-header:not(.p-disabled).p-highlight .p-panelmenu-header-content {
    background: var(--opex-green) !important;
    border-color: var(--opex-green) !important;
    color: #212529 !important;
}

/* .p-panelmenu .p-toggleable-content {
    height: 0;
}

.p-panelmenu .p-toggleable-content.p-panelmenu-expanded {
    height: auto;
} */

.p-menu {
    width: 100%;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    background: var(--opex-green) !important;
    border-color: var(--opex-green) !important;
}

.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: var(--opex-green) !important;
    border-color: var(--opex-green) !important;
}

.status-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
}

.status-active,
.status-concluded,
.status-completed {
    background: #C8E6C9;
    color: #256029;
}

.status-disabled {
    background: #FEEDAF;
    color: #8a5340;
}

.status-deleted {
    background: #FFCDD2;
    color: #c63737;
}

.status-open,
.status-requested {
    background: #b3e5fc;
    color: #23547b;
}

.status-submitted {
    background: #fff56a;
    color: #735e48;
}

.status-draft {
    background: #cccccc;
    color: #171717;
}

.status-rejected {
    background: #c63737;
    color: #ffcdd2;
}

.status-accepted {
    background: #256029;
    color: #c8e6c9;
}

.status-offline {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
    background: #c63737;
    color: #ffcdd2;
}

.ui-card-container {
    min-height: 180px;
}

.ui-card {
    background: #555;
    color: #fff;
    min-width: 200px;
}

.ui-card-unknown {
    border-bottom: 10px solid #555;
}

.ui-card-good {
    /*border-bottom: 10px solid #5e9700;*/
    color: #5e9700;
}

.ui-card-ok {
    /*border-bottom: 10px solid #9041c6;*/
    color: #ac83c7;
}

.ui-card-bad {
    /*border-bottom: 10px solid #b9436b;*/
    color: #b9436b;
}

.ui-card-legend-good {
    background-color: #5e9700;
}

.ui-card-legend-ok {
    background-color: #9041c6;
}

.ui-card-legend-bad {
    background-color: #b9436b;
}

.cardtip {
    position: relative;
    display: inline-block;
}

.cardtip .cardtiptext {
    visibility: hidden;
    /*width: 280px;*/
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #171717;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 105%;
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 0.75rem;
}

.cardtip .cardtiptext.left {
    margin-left: -160px !important;
}

.cardtip .cardtiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
}

.cardtip:hover .cardtiptext {
    visibility: visible;
    opacity: 1;
}

.cardtiptext p strong {
    color: var(--opex-green);
    font-weight: 700;
}

.chart-wrapper-full-screen {
    position: fixed;
    top: 2.5vh !important;
    left: 2.5vw !important;
    height: 95vh !important;
    width: 95vw !important;
    z-index: 1200;
    border: 1px solid #cccccc;
    background-color: var(--panel-background-color);
    overflow: hidden;
    overflow-y: scroll;
}

.chart-panel-full-screen {
    height: 98%;
}

.chart-panel-small-container {
    min-height: 200px;
}

.chart-panel-small {
    height: 200px;
    background-color: var(--panel-background-color);
}

.chart-panel-container {
    min-height: 420px;
}

.chart-panel {
    min-height: 400px;
    height: 100%;
    background-color: var(--panel-background-color);
}

.chart-panel-no-background {
    height: 100%;
    padding: 2px;
}

.chart-panel-tenant-dashboard {
    min-height: 310px;
    height: 100%;
}

.chart-panel-tall-container {
    min-height: 720px;
}

.chart-panel-tall {
    height: 700px;
    background-color: var(--panel-background-color);
}

.chart-panel-massive-container {
    min-height: 920px;
}

.chart-panel-massive {
    height: 900px;
    background-color: var(--panel-background-color);
}

.chart-panel-no-height {
    background-color: var(--panel-background-color);
}

.chart-modal-button {
    background-color: rgba(59, 130, 246, 0.04);
    color: var(--opex-green);
    border: 1px solid;
    margin: 4px;
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
    border-radius: 6px;
    cursor: pointer;
}


.chart-modal-button-disabled {
    background-color: rgba(59, 130, 246, 0.04);
    color: #324608;
    border: 1px solid;
    margin: 4px;
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
    border-radius: 6px;
    text-decoration: line-through;
    cursor: pointer;
}

.chart-modal-button:hover {
    background-color: var(--opex-green);
    color: #fff;
}

.chart-modal-button-selected {
    background-color: var(--opex-green);
    color: #fff;
    border: 1px solid;
    margin: 4px;
    font-size: 1rem;
    padding: 0.75rem 1.25rem;
    border-radius: 6px;
    cursor: pointer;
}

.chart-export-watermark {
    background: url('/assets/brand/app-logo-on-dark.svg') top no-repeat;
    background-size: 25%;
    background-blend-mode: darken;
    background-position-y: 50px;
}

.chart-legend-panel {
    height: 60px; 
    font-size: 0.75rem; 
    overflow-y: scroll; 
    overflow-x: hidden; 
    border: 1px dashed gray;
}

.message-content {
    border: 2px solid #7a7878;
    background: #7a7878;
    border-radius: 16px;
    border-bottom-left-radius: 0;
}

.my-message-content {
    border: 2px solid #7cae15;
    background: #7cae15;
    border-radius: 16px;
    border-bottom-right-radius: 0;
}

.unit-card-container {
    display: flex;
    flex-wrap: wrap;
}

/* .unit-card-bounds {
    width: 435px;
    height: 160px;
} */

.unit-card {
    width: 420px;
    display: table-cell;
}

.unit-card-black-text {
    color: black;
}

.unit-card-online-mixed {
    background-image: linear-gradient(to bottom right, var(--opex-green), #1cc0d5);
    color: black;
}

.unit-card-online-diesel {
    background-color: #1cc0d5;
    color: black;
}

.unit-card-online-gas {
    background-color: var(--opex-green);
    color: black;
}

.unit-card-offline {
    background-color: gray;
    color: black;
}

.unit-card-clickable {
    cursor: pointer;
}

.unit-card-table {
    display: table;
    width: 100%;
    padding: 5px;
}

.unit-card-headers {
    width: 100%;
    text-align: center;
}

.unit-card-row {
    display: table-row;
}

.unit-card-cell {
    display: table-cell;
    text-align: center;
    width: 95px;
    margin-left: 5px;
    margin-right: 5px;
}

/* .p-calendar .p-datepicker {
    left: auto !important;
    right: 0px !important;
} */

/* scrollbar-design*/

/* width */
::-webkit-scrollbar {
    width: 8px;

}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #171717;
    border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #353F3F;
    border: 1px solid #171717;
    border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: var(--opex-green);
}

.swal2-container {
  z-index: 100000 !important;
}

.fake-color-picker {
    height: 20px;
    width: 20px;
    border-radius: 4px;
    display: inline-block;
    margin-right: 10px;
    vertical-align: middle;
}

.notification-banner-panel {
    background: #fff2e2; 
    border: solid #cc8925; 
    border-width: 0 0 0 6px; 
    color: #cc8925; 
    padding: 1.25rem 1.75rem; 
    margin: 1rem 0; 
    border-radius: 6px;
}

.notification-banner-icon {
    font-size: 2rem !important;
    margin-right: 0.5rem; 
    display: inline-block;
}

.notification-banner-message-container {
    vertical-align: middle; 
    font-size: 1rem; 
    margin-left: 0.5rem; 
    display: inline-block;
}

.dull-text {
    font-size: 0.8em; 
    color: #888888;
}

#theme-menu .p-submenu-list {
    right: 100%;
    left: unset;
}

.clock-panel {
    width: 300px;
}

.tenant-dashboard-asset-card {
    display:inline-block; 
    background-color: var(--panel-background-color); 
    border-radius: 12px 12px 8px 8px; 
    border-width: 12px 1px 1px 1px; 
    border-style: solid; 
    border-color: var(--panel-background-color);
    width: 500px;
}

.tenant-dashboard-asset-card-dot-wrapper {
    width: 100px; 
    margin: auto;
}

.tenant-dashboard-asset-card-dot {
    height: 100px; 
    width: 100px; 
    border-radius:50%; 
    display:inline-block; 
    text-align:center; 
    vertical-align:middle; 
    line-height:100px; 
    font-size:1.5em; 
    font-weight: bold; 
    color: #fff;
}

.tenant-dashboard-pie-chart {
    width: 100%;
    height: 310px;
}

.page-panel-header {
    font-weight: bold; 
    font-size: 1.25em;
    border-bottom: 1px solid var(--light-gray);
}

.page-panel-header-row {
    line-height: 38px; 
    vertical-align: middle;
}

.tenant-dashboard-emissions-history-chart {
    background-color: var(--panel-background-color);
    height:100%; 
    min-height:640px;
}

.tenant-dashboard-map-chart {
    background-color: var(--panel-background-color);
    height:100%; 
    min-height:640px;
}

.chart-table-legend {
    width: 275px;
}

.chart-table-legend-row td {
    border-width: 8px 0px 0px 0px; 
    border-style: solid; 
    padding-top: 4px;
    padding-bottom: 8px;
}

.chart-table-legend-row td:first-child {
    border-top-left-radius: 8px;
}
.chart-table-legend-row td:last-child {
    border-top-right-radius: 8px;
}

.chart-table-legend-row-total td {
    border-width: 8px 0px 0px 0px; 
    border-style: solid; 
    border-color: var(--light-gray);
    padding-top: 4px;
}

.chart-table-legend-row-total td:first-child {
    border-top-left-radius: 8px;
}
.chart-table-legend-row-total td:last-child {
    border-top-right-radius: 8px;
}

.page-header-asset-selector {
    color: var(--default-font-color);
}

@media screen and (min-width: 3840px) {
    .dashboard-wrapper {
        font-size: 1.75em;
    }

    .clock-panel {
        width: 500px;
    }

    .tenant-dashboard-asset-card-dot-wrapper {
        width: 200px;
    }

    .tenant-dashboard-asset-card-dot {
        height: 150px; 
        width: 150px;
        line-height: 150px;
    }

    .tenant-dashboard-pie-chart {
        height: 550px;
    }
    
    .tenant-dashboard-emissions-history-chart {
        min-height: 1120px;
    }

    .chart-table-legend {
        width: 500px;
    }
}

.global-dashboard-tenant-card {
    display:inline-block; 
    background-color: var(--panel-background-color); 
    border-radius: 8px 8px 8px 8px; 
    border-width: 1px 1px 1px 1px; 
    border-style: solid; 
    border-color: var(--light-blue);
    min-width: 500px;
}

.global-dashboard-asset-card {
    display:inline-block; 
    background-color: var(--panel-background-color); 
    border-radius: 12px 12px 8px 8px; 
    border-width: 12px 1px 1px 1px; 
    border-style: solid; 
    border-color: var(--light-blue);
    width: 450px;
}

.am5-modal-curtain {
    background: rgba(0, 0, 0, 0.5)!important;
}
.am5-modal-wrapper {
    background: rgba(0, 0, 0, 0.5)!important;
}

.am5-modal-content {
    background: rgb(0, 0, 0)!important;
    box-shadow: rgba(255, 255, 255, 0.45) 0px 0px 36px 0px !important;
    color: rgb(255, 255, 255) !important;
}